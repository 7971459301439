import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../layout';
import SEO from '../seo';
import styles from '../../styles/pages/index-ibi.module.scss';
import BusinessListItem from '../busines-list-item';
import ShapeSvg from '../../icons/promote/shape.svg';
import Arrow from '../../icons/index-ibi/arrow.svg';

import { callToActionList } from './ibi-index-config';
import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import BannerRefferal from '../banner-refferal';
import Loader from '../loader';
import { selectLoader } from '../../store/selectors/global';
import { externalRedirect } from '../../helpers/utils';
import { getSearchParam } from '../../helpers/url';
import { fetchRefferal } from '../../store/actions';

const query = graphql`
  query {
    image0: file(relativePath: { eq: "ibi-index/image0.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image1: file(relativePath: { eq: "ibi-index/image1.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "ibi-index/image2.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "ibi-index/image3.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const strings = {
  onlineLine: {
    topTitle: 'Right platform. Right people. Right products.',
    topPreTitle: 'We connect Publishers to brands and brands to customers.',
    topDescription:
      'Grow your business faster with intelligent partner automation. We are one of the largest marketplaces for online digital services. From online learning to travel and tech, there’s a solution available for every need. We enable you to grow with an unlimited scale while acquiring practical skills and learning from industry leaders.',
  },
  ibiworldtwo: {
    topTitle: 'Right platform. Right people. Right products.',
    topPreTitle: 'We connect Publishers to brands and brands to customers.',
    topDescription:
      'Grow your business faster with intelligent partner automation. We are one of the largest marketplaces for online digital services. From online learning to travel and tech, there’s a solution available for every need. We enable you to grow with an unlimited scale while acquiring practical skills and learning from industry leaders.',
  },
  ibiusa: {
    topTitle: 'Promote Products. Earn Commissions.',
    topPreTitle: 'Boost your earning by selling the products on our. It’s that easy!',
    topDescription:
      'Welcome to one of the most extensive publisher marketing programs in the online sphere. Our program gives you the freedom to work anywhere, anytime you want, while enabling you to grow on an unlimited scale, acquiring practical skills and learning from industry leaders. Whether you are looking to earn extra monthly income or build your online business, becoming our publisher will get you there.',
  },
  ibiusatwo: {
    topTitle: 'Promote Products. Earn Commissions.',
    topPreTitle: 'Boost your earning by selling the products on our. It’s that easy!',
    topDescription:
      'Welcome to one of the most extensive publisher marketing programs in the online sphere. Our program gives you the freedom to work anywhere, anytime you want, while enabling you to grow on an unlimited scale, acquiring practical skills and learning from industry leaders. Whether you are looking to earn extra monthly income or build your online business, becoming our publisher will get you there.',
  },
};

const IbiIndex = () => {
  const [globalLoader, setGlobalLoader] = useState(true);
  const data = useStaticQuery(query);
  const instance = process.env.GATSBY_INSTANCE_NAME;
  const instStrings = strings[instance];
  const dispatch = useDispatch();

  const expertiseTextTitle = instStrings?.topTitle;
  const expertiseTextPre = instStrings?.topPreTitle;
  const expertiseTextDescription = instStrings?.topDescription;

  useEffect(() => {
    if (!window.location.href.includes('enrollment') && !window.location.href.includes('Token')) {
      setGlobalLoader(false);
    }

    const countryQuery = getSearchParam('country', window.location.href);
    const enroller = countryQuery && countryQuery.split('/')[2];

    if (enroller) {
      dispatch(fetchRefferal(enroller));
      createNavigateTo('/pre-checkout')();
    }
  }, []);

  return (
    <Layout fluid hideLoader={globalLoader} pageWrapperClassName={styles.page_wrapper}>
      {globalLoader && <Loader isLoading whiteBg />}
      <SEO title="Home - Publisher" />
      <div className="refferalWrapper">
        <BannerRefferal />
      </div>
      <div className={clsx(styles.blockWrapper, styles.topBlock)}>
        <div className={styles.textWrapper}>
          <h1>
            Your fast-track <span className={styles.titleNextLine}>to freedom.</span>
          </h1>
          <p>A platform built around growing your business.</p>
        </div>
        <div className={clsx(styles.imageContainer)}>
          <Img fluid={data?.image0.childImageSharp.fluid} />
        </div>
      </div>

      <div className={clsx(styles.blockWrapper, styles.marginZero, styles.paddingZero)}>
        <div className={styles.expertiseText}>{expertiseTextTitle}</div>
      </div>
      <div className={clsx(styles.blockWrapper, styles.marginZero, styles.paddingZero)}>
        <div className={styles.expertiseTextDescription}>{expertiseTextPre}</div>
      </div>

      <div className={clsx(styles.blockWrapper)}>
        <div className={styles.imageContainer}>
          <Img fluid={data?.image1.childImageSharp.fluid} />
        </div>
        <div className={styles.textWrapper}>
          <p>{expertiseTextDescription}</p>
        </div>
      </div>

      <div className={styles.youGotThisContainer}>
        <div className={clsx(styles.blockWrapper, styles.marginZero, styles.paddingZero)}>
          <div className={styles.expertiseText}>You got this!</div>
        </div>
        <div className={clsx(styles.blockWrapper, styles.marginZero, styles.paddingZero)}>
          <p className={styles.youGotThis}>
            Whether you are new to influence marketing or an established online business owner
            looking to scale, we enable you to increase sales without increasing the work.
          </p>
        </div>
      </div>

      <div className={styles.blockWrapper}>
        <div className={styles.textWrapper}>
          <p className={styles.empowerTopText}>WE EMPOWER YOU TO</p>
          <p className={styles.empowerBottomText}>
            Set your <span className={styles.titleNextLine}>bar higher</span>
          </p>
        </div>
        <div className={styles.imageContainer}>
          <Img fluid={data?.image2.childImageSharp.fluid} />
        </div>
      </div>

      <div className={styles.listWrapper}>
        <div className={styles.callToActionList}>
          {callToActionList.map((item) => (
            <BusinessListItem
              key={item.title}
              iconWrapperStyles={styles.iconStyles}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ))}
        </div>
      </div>

      <div className={clsx(styles.blockWrapper)}>
        <div className={styles.textWrapper}>
          <p className={styles.promoteTitle}>What’s in it for you to promote our Products</p>
          <p>
            Tired of wading through endless offers that don’t convert? It’s time you discovered the
            game-changing power of this opportunity.
          </p>
          <Link to={pageLinks.promote} className={styles.promoteLink}>
            <span>Promote</span>
            <Arrow />
          </Link>
          <p className={styles.promoteBottomDescription}>Earn more by promoting our products</p>
        </div>
        <div className={styles.imageContainer}>
          <Img fluid={data?.image3.childImageSharp.fluid} />
        </div>
      </div>

      <div className={styles.blockWrapper}>
        <div className={styles.bottomImage}>
          <ShapeSvg />
        </div>
        <div className={styles.signContainer}>
          <div className={styles.signTitle}>Dare to think big!</div>
          <Link to={pageLinks.signUp2} className={styles.link}>
            Become a Publisher
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default IbiIndex;
